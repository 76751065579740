.o-basket-view--container {
  position: absolute;
  right: 0;
}

@include mobileOnly() {
  .o-basket-view--container {
    width: 100%;
  }
}

@include desktopOnly() {
  .o-basket-view--container {
    width: 504px;
  }
}

.o-modal-dropdown {

  &__container {
    z-index: 2;
    position: absolute;
    width: 260px;
  }

  &__overlay {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $modalBlack;
    z-index: 1;
    cursor: pointer;
  }
}

/* Mobile functionality */
@include mobileOnly() {
  .o-modal-dropdown__container {
    margin-left: 0;
    width: 100%;
  }

}
.o-signup-input {
  border: 1px solid $borderDarkGrey;
  @extend .u-w-all;
  @extend .u-br--vsmall;
  height: 59px;
  padding: 20px;
}
.o-signup-input:focus {
  height: 60px;
  border-radius: 0;
  border: 1px solid $borderGrey;
  border-bottom: 2px solid $violet;
}

.o-loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid $magenta; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Mobile and desktop

@include mobileOnly() {
  .o-signup__vendor-pref-container {
    flex-wrap: wrap;
    justify-content: center;

    button {
      width: 50%;
      // @extend .u-border--darkgrey; not available in Sass
      border: 1px;
    }
  }
}

@include desktopOnly {
  .o-signup-card {
    width: 730px;
  }
  .o-signup-card--content {
    padding-left: 100px;
    padding-right: 100px;
    font-size: 14px;
  }
}

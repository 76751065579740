$caret-marker-half-width: 14px;
$caret-marker-height: 14px;

.o-caret-marker {
  // position a point (the tip of the triangle)
  position: absolute;
  top: -$caret-marker-height;
  width: 0;
  height: 0;
  // create the triangle using a border
  border-left: $caret-marker-half-width solid transparent;
  border-right: $caret-marker-half-width solid transparent;
  border-bottom: $caret-marker-height solid white;
}

.o-caret-marker--purple {
  @extend .o-caret-marker;
  border-bottom: $caret-marker-height solid $purple;
}

.o-terms-of-use{
    font-size: 14px;
}
.o-terms-of-use > ol {
  font-weight: 700;
  font-size: x-large;
  padding-left: 25px;
}
.o-terms-of-use > p {
  padding-bottom: 1rem;

}
.o-terms-of-use > h1 {
  font-weight: 700;
  font-size: xx-large;
}
.o-terms-of-use > h3 {
  font-weight: 700;
  font-size: large;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}

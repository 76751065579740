.o-basket-summary-container {
  @extend .u-text-unselectable;
  @extend .u-align-center;
  @extend .u-text-right;
  @extend .u-main-font--vvsmall;
  @extend .u-pl--8;
  & div {
    @extend .u-text-oneline;
  }
}
@include mobileOnly() {
  .o-basket-summary-container {
    line-height: 7px;
  }
}

@include desktopOnly() {
  .o-basket-summary-container {
    padding-left: "24px";
    padding-right: "24px";
    margin-top: "8px";
  }
}
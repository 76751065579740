// Breakpoint
$desktop-size: 600px;
$tablet-size: 820px;

$very-small-size: 335px;
$small-size: 375px;


@mixin notForSmallMobile() {
  @media screen and (min-width: $very-small-size) {
    @content;
  }
}

@mixin smallMobileOnly() {
  @media screen and (max-width: $small-size) {
    @content;
  }
}

@mixin mobileOnly() {
  @media screen and (max-width: $desktop-size) {
    @content;
  }
}

@mixin tabletOnly() {
  @media only screen and (max-width: $tablet-size) and (orientation: portrait) {
      @content;
    }
}

@mixin desktopOnly() {
  @media screen and (min-width: $desktop-size) {
    @content;
  }
}

.flip {
  transform: rotateY(180deg);
  transition: transform 0.8s;
}

.reset {
  transform: rotateY(0deg);
  transition: transform 0.8s;
}

.card {
  backface-visibility: hidden;
}
/* Backgrounds */
.u-background--grocerize {
  background: linear-gradient(0deg, $magenta 0%, #e13153 100%);
}

.u-background--secondary {
  background: $charcoal;
}

.u-background--grey {
  background-color: $grey;
}

.u-background--lightgrey {
  background-color: $lightGrey;
}

.u-background--darkgrey {
  background-color: $borderDarkGrey;
}

.u-background--magenta {
  background-color: $magenta;
}

.u-background--white {
  background-color: $white;
}

.u-background--yellow {
  background-color: $yellow;
}

.u-background--pink {
  background-color: $pink;
}

.u-background--closeMatch {
  background-color: $closeMatchBlue;
}

.u-background--green {
  background-color: $confirmGreen;
}

.u-background--blue {
  background-color: $closeMatchBlue;
}

.u-background--coles {
  background-color: $coles;
}

.u-background--woolworths {
  background-color: $woolworths;
}

.u-background--purple {
  background-color: $purple;
}

.u-background--vividCyan {
  background-color: $vividCyan;
}

.u-background--fadein {
  background-color: transparent;
}

.u-background--red {
  background-color: $red;
}

.u-background--brightRed {
  background-color: $brightRed;
}

/* Wrap */
.u-wrap {
  max-width: 1280px;
  width: 100%;
  padding: 0px 40px;
  margin: 0 auto;
  padding-top: 48px;

  .u-wrap {
    padding: 0px;
  }
}

/*Wrap Text*/
.u-word-break {
  word-break: break-all;
}

.u-word-wrap {
  word-wrap: break-word;
}

/* Sizing */
.u-w--26 {
  width: 26px;
}

.u-w--45px {
  width: 45px;
}

.u-w--40px {
  width: 40px;
}

.u-w--130px {
  width: 130px;
}

.u-w-25pct {
  width: 25%;
}

.u-w-30pct {
  width: 30%;
}

.u-w-35pct {
  width: 35% !important;
}

.u-w-40pct {
  width: 40%;
}

.u-w-half {
  width: 50%;
}

.u-w-60pct {
  width: 60% !important;
}

.u-w-65pct {
  width: 65% !important;
}

.u-w-80pct {
  width: 80%;
}

.u-w-all {
  width: 100%;
}

.u-w-inherit {
  width: inherit;
}

.u-w-fit {
  width: fit-content;
}

.u-h--26{
  height: 26px;
}

.u-h--50px {
  height: 50px;
}

.u-h--45px {
  height: 45px;
}

.u-h--40px {
  height: 40px;
}

.u-h--70px {
  height: 70px;
}

.u-h-all {
  height: 100%;
}
.u-h-fit {
  height: fit-content;
}
.u-max-height--48 {
  max-height: 48px;
}
.u-min-height--48 {
  min-height: 48px;
}
.u-h-max {
  max-height: 800px;
}

// try to avoid creating .u-vh as it doesn't work well in mobile browsers https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html
.u-vw {
  width: 100vw;
}

/* Positioning */

.u-hide {
  display: none;
}

.u-overflow-scroll {
  overflow: scroll;
}

.u-overflow-auto {
  overflow: auto;
}

.u-overflow-hidden {
  overflow: hidden;
}

.u-overflow-wrap {
  overflow-wrap: break-word;
}

.u-overflow--scroll-x--hidden {
  overflow-x: hidden;
}

.u-overflow--scroll-x {
  overflow-x: scroll;
}

.u-overflow--scroll-y {
  overflow-y: scroll;
}

.u-overflow--scroll-y--hidden {
  overflow-y: hidden;
}

.u-tidy-scrollbar {
  overflow-y: scroll;
  scrollbar-gutter: stable both-edges;
}

/*Hide scrollbar for Chrome, Safari and Opera */
.u-overflow--scroll--hidden::-webkit-scrollbar {
  display: none;
}

.u-overflow--scroll--hidden {
  /*Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;

  /*Hide scrollbar for Firefox */
  scrollbar-width: none;
}

/* Works on Chrome, Edge, and Safari */
.u-overflow--scroll--thinner::-webkit-scrollbar {
  width: 8px;
}

.u-overflow--scroll--thinner::-webkit-scrollbar-thumb {
  background-color: #abaaaa8c;
}

/* Works on Firefox */
.u-overflow--scroll--thinner {
  scrollbar-width: thin;
}



.u-block {
  display: block;
}

.u-flex {
  display: flex;
}

.u-inline-flex {
  display: inline-flex;
}

.u-z-index---1 {
  z-index: -1;
}

.u-z-index--0 {
  z-index: 0;
}
.u-z-index--1 {
  z-index: 1;
}

.u-z-index--2 {
  z-index: 2;
}

.u-flex-column {
  flex-direction: column;
}

.u-flex--row-reverse {
  flex-direction: row-reverse;
}

.u-flex-none {
  flex: none;
}

.u-flex-1 {
  flex: 1;
}

.u-flex-wrap {
  flex-flow: wrap;
}

.u-align-left {
  align-items: left;
}

.u-align-center {
  align-items: center;
}

.u-align-baseline {
  align-items: baseline;
}

.u-align-self-center {
  align-self: center;
}

.u-relative {
  position: relative;
}

.u-absolute {
  position: absolute;
}

.u-fixed {
  position: fixed;
}

.u-sticky {
  position: sticky;
}

.u-top--0 {
  top: 0;
}

.u-top--minus100 {
top: -100px;
}

.u-left--0 {
  left: 0;
}

.u-right--18 {
  right: 18px;
}

.u-right--0 {
  right: 0;
}

.u-bottom--0 {
  bottom: 0;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

.u-object-fit--cover {
  object-fit: cover;
}

/* Justify */

.u-justify-between {
  justify-content: space-between;
}

.u-justify-space-around {
  justify-content: space-around;
}

.u-justify-end {
  justify-content: end;
}

.u-justify-flex-end {
  justify-content: flex-end;
}

.u-justify-center {
  justify-content: center;
}

.u-justify-left {
  justify-content: left;
}

/* Other */

.u-vis--hidden {
  visibility: hidden;
}

.u-float--right {
  float: right;
}

.u-cursor--pointer {
  cursor: pointer;
}

.u-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.u-opacity--point5 {
  pointer-events: none;
  opacity: 0.5;
}

.u-opacity--5 {
  opacity: 0.5;
}

.u-rotate--negative90deg {
  transform: rotate(-90.1deg);
  -webkit-transform: rotate(-90.1deg);
  -ms-transform: rotate(-90.1deg);
  ;
}

.u-rotate--90deg {
  transform: rotate(90.1deg);
  -webkit-transform: rotate(90.1deg);
  -ms-transform: rotate(90.1deg);
  ;
}

.u-rotate--negative360deg {
  transform: rotate(-360deg);
  -webkit-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
}

.u-tooltip-wrap {
  position: relative;
}

.u-tooltip-wrap .u-tooltip-content {
  visibility: hidden;
  width: 100%;
  background-color: $charcoal;
  color: #fff;
  text-align: left;
  padding: 10px;
  line-height: 1.3rem;
  border-radius: 8px;
  position: absolute;
  z-index: 1;
  max-height: 236px;
  overflow-y: auto;
}

.u-tooltip-wrap:hover .u-tooltip-content {
  visibility: visible;
}

.u-ease-slow {
  transition: all 450ms ease-in-out;
}

.u-tooltip-wrap--interactive .u-tooltip-content--interactive {
  visibility: hidden;
  width: 100%;
  background-color: #8000FF;
  color: #fff;
  text-align: left;
  padding: 10px;
  line-height: 1.3rem;
  border-radius: 8px;
  position: absolute;
  z-index: 1;
  max-height: 236px;
  overflow-y: auto;
}

/* To add tooltip arrow */
.u-tooltip-wrap--interactive .u-tooltip-content--interactive::after {
  content: "";
  // position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  // border-color: #8000FF transparent transparent transparent;
  border-color: #8000FF
}

.u-carousel-dots {
  display: inline-block;
  left: 50%;
  margin-top: 2rem;
  position: absolute;
  transform: translateX(-50%);

    .u-dot {
      background: #ccc;
      border: 0;
      border-radius: 50%;
      cursor: pointer;
      height: 2rem;
      margin: 0.1rem;
      outline: none;
      transform: scale(0.3);
      width: 2rem;

      &.active {
        background: $violet;
      }
    }
}

.u-text-transform--uppercase {
  text-transform: uppercase;
}

.u-accent-violet {
  accent-color: $violet;
}

.u-opacity--8 {
  opacity: .8;
}


/* Mobile functionality */

@include mobileOnly() {
  .u-wrap {
    padding: 0px 12px;
  }

  .u-hide--mobile {
    display: none;
  }

  .u-flex--mobile {
    display: flex;
  }

  .u-w-all--mobile {
    width: 100%;
  }

  .u-align-center--mobile {
    align-items: center;
  }

  .u-justify-center--mobile {
    justify-content: center;
  }

  .u-text-center--mobile {
    text-align: center;
  }

  .u-bottom--0--mobile {
    bottom: 0;
  }

  // Set a variable height for main app to avoid browser nav bar hiding part of the grocerize page
  .u-h--var--mobile {
    height: calc(var(--vh, 1vh) * 100);
  }

  .u-h--half--var--mobile {
    height: (calc(var(--vh, 1vh) * 100))/2;
  }

  .u-overflow-scroll--mobile {
    overflow: scroll;
  }
  .u-flex--mobile {
    display: flex;
  }
  .u-overflow--scroll-y--mobile {
    overflow-y: scroll;
  }
  .u-h--34--mobile {
    height: 34px;
  }
  .u-w--34--mobile {
    width: 34px;
  }
  .u-h--70--mobile {
    height: 70px;
  }
  .u-h--75px--mobile {
    height: 75px;
  }

}

@include tabletOnly() {
  .u-hide--tablet {
    display: none;
  }
}

@include desktopOnly() {
  .u-hide--desktop {
    display: none;
  }

  .u-flex--desktop {
    display: flex;
  }

  .u-h--75px--desktop {
    height: 75px;
  }

  .u-w-all--desktop {
    width: 100%;
  }

  .u-w-250--desktop {
    width: 250;
  }

  .u-w-1300--desktop {
    width: 1300px;
  }

  .u-w-half--desktop {
    width: 50%;
  }

  .u-h-all--desktop {
    height: 100%;
  }

  .u-mh-fit--desktop {
    min-height: calc(100vh - 258px); //deducted header and footer height 
  }

  .u-block--desktop {
    display: block;
  }

  .u-w-35pct--desktop {
    width: 35% !important;
  }

  .u-w-65pct--desktop {
    width: 65% !important;
  }

  .u-flex-1--desktop {
    flex: 1;
  }
}
/* Margins */

.u-mauto {
  margin: auto;
}

.u-mlauto {
  margin-left: auto;
}

​.u-mrauto {
  margin-right: auto;
}

.u-mtauto {
  margin-top: auto;
}

​.u-mbauto {
  margin-bottom: auto;
}

.u-m0auto {
  margin: 0 auto;
}

.u-mauto0 {
  margin: auto 0;
}

.u-m--2 {
  margin: 2px;
}
.u-m--4 {
  margin: 4px;
}

.u-m--8 {
  margin: 8px;
}

.u-m--12 {
  margin: 12px;
}

.u-m--16 {
  margin: 16px;
}

.u-m--20 {
  margin: 20px;
}

.u-m--24 {
  margin: 24px;
}

.u-m--32 {
  margin: 32px;
}

.u-m--50 {
  margin: 50px;
}

.u-m--90 {
  margin: 90px;
}

.u-m--120 {
  margin: 120px;
}

.u-ml--35 {
  margin-left: 35px;
}

.u-mv--4 {
  margin-top: 4px;
  margin-bottom: 4px;
}
.u-mv--8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.u-mv--12 {
  margin-top: 12px;
  margin-bottom: 12px;
}
.u-mv--16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.u-mv--24 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.u-mv--32 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.u-mv--48 {
  margin-top: 48px;
  margin-bottom: 48px;
}

.u-mv--80 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.u-mv--100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.u-mv--120 {
  margin-top: 120px;
  margin-bottom: 120px;
}

.u-mv--140 {
  margin-top: 140px;
  margin-bottom: 140px;
}

.u-mv--280 {
  margin-top: 280px;
  margin-bottom: 280px;
}

.u-mh--4 {
  margin-left: 4px;
  margin-right: 4px;
}
.u-mh--8 {
  margin-left: 8px;
  margin-right: 8px;
}

.u-mh--12 {
  margin-left: 12px;
  margin-right: 12px;
}

.u-mh--16 {
  margin-left: 16px;
  margin-right: 16px;
}

.u-mh--24 {
  margin-left: 24px;
  margin-right: 24px;
}

.u-mh--32 {
  margin-left: 32px;
  margin-right: 32px;
}

.u-mh--120 {
  margin-left: 120px;
  margin-right: 120px;
}

.u-mh--minus20pct {
  margin-left: -20%;
  margin-right: -20%;
}

.u-mt--minus28 {
  margin-top: -28px;
}

.u-mt--1 {
  margin-top: 1px;
}
.u-mt--3 {
  margin-top: 3px;
}
.u-mt--4 {
  margin-top: 4px;
}
.u-mt--6 {
  margin-top: 6px;
}
.u-mt--8 {
  margin-top: 8px;
}

.u-mt--12 {
  margin-top: 12px;
}
.u-mt--16 {
  margin-top: 16px;
}

.u-mt--24 {
  margin-top: 24px;
}

.u-mt--32 {
  margin-top: 32px;
}

.u-mt--48 {
  margin-top: 48px;
}

.u-mt--64 {
  margin-top: 64px;
}

.u-mt--80 {
  margin-top: 80px;
}

.u-mt--120 {
  margin-top: 120px;
}

.u-t--243 {
  top: 243px;
}

.u-mt--minus6 {
  margin-top: -6px;
}

.u-mt--20pct {
  margin-top: 20%;
}

.u-mt--30pct {
  margin-top: 30%;
}

.u-mt--50pct {
  margin-top: 50%;
}

.u-mb--minus14 {
  margin-bottom: -14px;
}

.u-mb--8 {
  margin-bottom: 8px;
}

.u-mb--16 {
  margin-bottom: 16px;
}

.u-mb--24 {
  margin-bottom: 24px;
}

.u-mb--32 {
  margin-bottom: 32px;
}

.u-mb--48 {
  margin-bottom: 48px;
}

.u-mb--64 {
  margin-bottom: 64px;
}

.u-mr--0 {
  margin-right: 0px;
}

.u-mr--2 {
  margin-right: 2px;
}

.u-mr--4 {
  margin-right: 4px;
}

.u-mr--8 {
  margin-right: 8px;
}

.u-mr--12 {
  margin-right: 12px;
}

.u-mr--16 {
  margin-right: 16px;
}

.u-mr--24 {
  margin-right: 24px;
}
.u-mr--32 {
  margin-right: 32px;
}

.u-ml--4 {
  margin-left: 4px;
}

.u-ml--6 {
  margin-left: 6px;
}

.u-ml--8 {
  margin-left: 8px;
}

.u-ml--12 {
  margin-left: 12px;
}

.u-ml--16 {
  margin-left: 16px;
}

.u-ml--20 {
  margin-left: 20px;
}
.u-ml--24 {
  margin-left: 24px;
}
.u-ml--48 {
  margin-left: 48px;
}
.u-ml--minus10 {
  margin-left: -10px;
}
.u-ml--minus16 {
  margin-left: -16px;
}
.u-mr--minus16 {
  margin-right: -16px;
}

.u-h--webkit-fill {
  height: -webkit-fill-available;
}

/* Padding */

.u-p--1 {
  padding: 1px;
}
.u-p--2 {
  padding: 2px;
}
.u-p--4 {
  padding: 4px;
}
.u-p--6 {
  padding: 6px;
}

.u-p--8 {
  padding: 8px;
}

.u-p--12 {
  padding: 12px;
}

.u-p--16 {
  padding: 16px;
}

.u-p--24 {
  padding: 24px;
}

.u-p--32 {
  padding: 32px;
}

.u-p--48 {
  padding: 48px;
}

.u-pv--4 {
  padding-top: 4px;
  padding-bottom: 4px;
}
.u-pv--6 {
  padding-top: 6px;
  padding-bottom: 6px;
}
.u-pv--8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.u-pv--10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.u-pv--12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.u-pv--16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.u-pv--18 {
  padding-top: 18px;
  padding-bottom: 18px;
}

.u-pv--20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.u-pv--24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.u-pv--32 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.u-pv--48 {
  padding-top: 48px;
  padding-bottom: 48px;
}

.u-pv--64 {
  padding-top: 64px;
  padding-bottom: 64px;
}

.u-pv--120 {
  padding-top: 120px;
  padding-bottom: 120px;
}

.u-pv--180 {
  padding-top: 180px;
  padding-bottom: 180px;
}
.u-ph--2 {
  padding-left: 2px;
  padding-right: 2px;
}
.u-ph--4 {
  padding-left: 4px;
  padding-right: 4px;
}

.u-ph--6 {
  padding-left: 6px;
  padding-right: 6px;
}
.u-ph--8 {
  padding-left: 8px;
  padding-right: 8px;
}

.u-ph--12 {
  padding-left: 12px;
  padding-right: 12px;
}

.u-ph--16 {
  padding-left: 16px;
  padding-right: 16px;
}

.u-ph--20 {
  padding-left: 20px;
  padding-right: 20px;
}

.u-ph--24 {
  padding-left: 24px;
  padding-right: 24px;
}

.u-ph--32 {
  padding-left: 32px;
  padding-right: 32px;
}

.u-ph--34 {
  padding-left: 34px;
  padding-right: 34px;
}

.u-ph--42 {
  padding-left: 42px;
  padding-right: 42px;
}
.u-ph--48 {
  padding-left: 48px;
  padding-right: 48px;
}
.u-ph--50 {
  padding-left: 50px;
  padding-right: 50px;
}

.u-ph--100 {
  padding-left: 100px;
  padding-right: 100px;
}

.u-pt--1 {
  padding-top: 1px;
}
.u-pt--2 {
  padding-top: 2px;
}
.u-pt--4 {
  padding-top: 4px;
}
.u-pt--6 {
  padding-top: 6px;
}

.u-pt--10 {
  padding-top: 10px;
}

.u-pt--8 {
  padding-top: 8px;
}
.u-pt--12 {
  padding-top: 12px;
}

.u-pt--16 {
  padding-top: 16px;
}

.u-pt--18 {
  padding-top: 18px;
}

.u-pt--20 {
  padding-top: 20px;
}
.u-pt--24 {
  padding-top: 24px;
}

.u-pt--32 {
  padding-top: 32px;
}
.u-pt--42 {
  padding-top: 42px;
}
.u-pt--48 {
  padding-top: 48px;
}
.u-pt--64 {
  padding-top: 64px;
}

.u-pt--100 {
  padding-top: 100px;
}
.u-pt--200 {
  padding-top: 200px;
}



.u-pb--4 {
  padding-bottom: 4px;
}
.u-pb--8 {
  padding-bottom: 8px;
}
.u-pb--10 {
  padding-bottom: 10px;
}
.u-pb--12 {
  padding-bottom: 12px;
}
.u-pb--16 {
  padding-bottom: 16px;
}

.u-pb--20 {
  padding-bottom: 20px;
}

.u-pb--24 {
  padding-bottom: 24px;
}

.u-pb--32 {
  padding-bottom: 32px;
}

.u-pb--48 {
  padding-bottom: 48px;
}

.u-pb--64 {
  padding-bottom: 64px;
}

.u-pl--4 {
  padding-left: 4px;
}
.u-pl--8 {
  padding-left: 8px;
}

.u-pl--10 {
  padding-left: 10px;
}

.u-pl--12 {
  padding-left: 12px;
}
.u-pl--14 {
  padding-left: 14px;
}
.u-pl--16 {
  padding-left: 16px;
}
.u-pl--18 {
  padding-left: 18px;
}
.u-pl--20 {
  padding-left: 20px;
}
.u-pl--24 {
  padding-left: 24px;
}
.u-pl--28 {
  padding-left: 28px;
}
.u-pl--32 {
  padding-left: 32px;
}
.u-pl--40 {
  padding-left: 40px;
}
.u-pl--48 {
  padding-left: 48px;
}
.u-pl--60 {
  padding-left: 60px;
}
.u-pl--64 {
  padding-left: 64px;
}
.u-pr--0 {
  padding-right: 0px;
}
.u-pr--4 {
  padding-right: 4px;
}
.u-pr--10 {
  padding-right: 10px;
}
.u-pr--12 {
  padding-right: 12px;
}
.u-pr--14 {
  padding-right: 14px;
}
.u-pr--16 {
  padding-right: 16px;
}
.u-pr--20 {
  padding-right: 20px;
}
.u-pr--24 {
  padding-right: 24px;
}

.u-pr--32 {
  padding-right: 32px;
}

.u-pr--48 {
  padding-right: 48px;
}

.u-p--48 {
  padding: 48px;
}

.u-p--60 {
  padding: 60px;
}

.u-pv--8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.u-pv--10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.u-pv--12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.u-pv--16 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.u-pv--20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.u-h--50 {
  height: 50px;
}

.u-stacked-on-mobile-split-screen-on-desktop {
  display: flex;
  > div {
    width: 50%;
  }
}

@include notForSmallMobile() {
  .u-ph--12--exclude-small-mobile {
    padding-left: 12px;
    padding-right: 12px;
  }
  .u-ph--8--exclude-small-mobile {
    padding-left: 8px;
    padding-right: 8px;
  }
  .u-pl--12-exclude-small-mobile {
    padding-left: 12px;
  }
}

@include smallMobileOnly() {
  .u-ph--0-small-mobile {
    padding-left: 0px;
    padding-right:0px;
  }
}

// Mobile spacing

@include mobileOnly() {
  .u-ph--8--mobile {
    padding-left: 8px;
    padding-right: 8px;
  }
  .u-ph--12--mobile {
    padding-left: 12px;
    padding-right: 12px;
  }
  .u-ph--16--mobile {
    padding-left: 16px;
    padding-right: 16px;
  }
  .u-pv--8--mobile {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .u-pv--16--mobile {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .u-pv--20--mobile {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .u-pv--24--mobile {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .u-pv--32--mobile {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .u-mr--2--mobile {
    margin-right: 2px;
  }
  .u-pt--4--mobile {
    padding-top: 4px;
  }
  .u-pt--12--mobile {
    padding-top: 12px;
  }
  .u-pt--32--mobile {
    padding-top: 32px;
  }
  .u-pt--100--mobile {
    padding-top: 100px;
  }
  .u-pb--10--mobile {
    padding-bottom: 10px;
  }
  .u-pb--20--mobile {
    padding-bottom: 20px;
  }
  .u-pb--32--mobile {
    padding-bottom: 32px;
  }
  .u-pb--40--mobile {
    padding-bottom: 40px;
  }
  .u-pb--34--mobile {
    padding-bottom: 34px;
  }
  .u-pl--12--mobile {
    padding-left: 12px;
  }
  .u-pl--24--mobile {
    padding-left: 24px;
  }
  .u-pl--32--mobile {
    padding-left: 32px;
  }
  .u-pl--40--mobile {
    padding-left: 40px;
  }
  .u-pr--24--mobile {
    padding-right: 24px;
  }
  .u-pr--16--mobile {
    padding-right: 16px;
  }

  .u-mt--12--mobile {
    margin-top: 12px;
  }

  .u-w-half--mobile {
    width: 50%;
  }
  .u-w-170px--mobile {
    width: 170px;
  }
  .u-h-150px--mobile {
    height: 150px;
  }

  .u-ml--21--mobile {
    margin-left: 21px;
  }

  .u-ml--18--mobile {
    margin-left: 18px;
  }
}

@include desktopOnly {
  .u-pv--11--desktop {
    padding-top: 11px;
    padding-bottom: 11px;
  }
  .u-pv--12--desktop {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .u-pv--20--desktop {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .u-pv--24--desktop {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .u-pv--34--desktop {
    padding-top: 34px;
    padding-bottom: 34px;
  }

  .u-ph--4--desktop {
    padding-left: 4px;
    padding-right: 4px;
  }
  .u-ph--16--desktop {
    padding-left: 16px;
    padding-right: 16px;
  }
  .u-ph--48--desktop {
    padding-left: 48px;
    padding-right: 48px;
  }
  .u-ph--100--desktop {
    padding-left: 100px;
    padding-right: 100px;
  }

  .u-p--48--desktop {
    padding: 48px;
  }
  .u-p--12--desktop {
    padding: 12px;
  }

  .u-mt--32--desktop {
    margin-top: 32px;
  }
  .u-ml--31--desktop {
    margin-left: 31px;
  }
  .u-ml--66--desktop {
    margin-left: 66px;
  }
  .u-ml--150--desktop {
    margin-left: 150px;
  }

  .u-mr--8--desktop {
    margin-right: 8px;
  }
  .u-mr--100--desktop {
    margin-right: 100px;
  }
  .u-pr--24--desktop {
    padding-right: 24px;
  }
  .u-pr--32--desktop {
    padding-right: 32px;
  }
  .u-ml--12--desktop {
    margin-left: 12px;
  }

  .u-w-70px--desktop {
    width: 70px;
  }
  .u-w-175px--desktop {
    width: 175px;
  }
  .u-w-180px--desktop {
    width: 180px;
  }
  .u-w-200px--desktop {
    width: 200px;
  }

  .u-h-21px--desktop {
    height: 21px;
  }
  .u-h-200px--desktop {
    height: 200px;
  }

  .u-h-116px--desktop {
    height: 116px;
  }

  .u-pl--20--desktop {
    padding-left: 20px;
  }
  .u-pl--50--desktop {
    padding-left: 50px;
  }
  .u-pl--100--desktop {
    padding-left: 100px;
  }

  .u-pr--50--desktop {
    padding-right: 50px;
  }

  .u-pt--24--desktop {
    padding-top: 24px;
  }

  .u-pt--48--desktop {
    padding-top: 48px;
  }

  .u-pb--30--desktop {
    padding-bottom: 30px;
  }

  .u-p--60--desktop {
    padding: 60px;
  }
}

.u-grid--items {
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);

  grid-row-gap: 16px;
  grid-column-gap: 16px;
  justify-content: left;
}

.u-grid {
  display: grid;
}

.u-place--items--center {
  place-items: center;
}

@include mobileOnly() {
  .u-grid--items {
    grid-template-columns: repeat(auto-fit, 100%);

    grid-row-gap: 8px;
    grid-column-gap: 8px;
    justify-content: center;
  }
}

@include desktopOnly() {
  .u-place--items--center--desktop {
    place-items: center;
  }
}

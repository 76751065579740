$xxSmallFontSize: 10px;
$xSmallFontSize: 12px;
$smallFontSize14: 14px;
$smallFontSize: 16px;
$mediumFontSize: 18px;
$mediumFontSize22: 22px;
$largeFontSize: 24px;
$bannerFontSize: 34px;
$xLargeFontSize: 48px;
$xxLargeFontSize: 64px;

$lineHeightMultiplier: 1.5;
$lineHeightReducer: 0.8;

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: $white;
  color: $black;
  font: $font;
  font-weight: 500;
}

// Colors
.u-color-white {
  color: $white;
}
//magenta is violet. we might want to change this later on
.u-color-magenta {
  color: $magenta;
}
.u-color-green {
  color: $confirmGreen;
}
.u-color-charcoal {
  color: $charcoalText;
}
.u-color-grey {
  color: lightgray;
}
.u-color-darkgrey {
  color: #646464;
}
.u-color-coles {
  color: $coles;
}
.u-color-woolworths {
  color: $woolworths;
}
.u-color-pink {
  color: $pink;
}

.u-color-closeMatch {
  color: $closeMatchBlue;
}

.u-extra-semi-bold {
  font-weight: 400;
}
.u-semi-bold {
  font-weight: 600;
}
.u-bold {
  font-weight: 700;
}

.u-bold-black {
  font-weight: 900;
}

.u-font--input {
  font-size: 22px !important;
}

.u-link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.u-underline {
  text-decoration: underline;
}
.u-no-decoration {
  text-decoration: none;
}

.u-text-oneline {
  white-space: nowrap;
}

.u-text-align-center {
  text-align: center;
}

.u-text-align-middle {
  vertical-align: middle;
}

.u-truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  .u-no-truncate {
    white-space: pre-wrap;
  }

  &--2l,
  &--3l,
  &--4l,
  &--5l {
    white-space: pre-line;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    height: 100%;

    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  &--3l {
    -webkit-line-clamp: 3;
  }

  &--4l {
    -webkit-line-clamp: 4;
  }

  &--5l {
    -webkit-line-clamp: 5;
  }
}

.u-main-font {
  font-family: $font_stack;
}

.u-main-font--vvsmall {
  font-family: $font_stack;
  font-size: $xxSmallFontSize;
  line-height: $lineHeightMultiplier * $xxSmallFontSize;
}

.u-main-font--vsmall {
  font-family: $font_stack;
  font-size: $xSmallFontSize;
  line-height: $lineHeightMultiplier * $xSmallFontSize;
}

.u-main-font--small {
  font-family: $font_stack;
  font-size: $smallFontSize;
  line-height: $lineHeightMultiplier * $smallFontSize;
}

.u-main-font--14--small {
  font-family: $font_stack;
  font-size: $smallFontSize14;
  line-height: $lineHeightMultiplier * $smallFontSize14;
}

.u-main-font--medium {
  font-family: $font_stack;
  font-size: $mediumFontSize;
  line-height: $lineHeightMultiplier * $mediumFontSize;
}

.u-main-font--22--medium {
  font-family: $font_stack;
  font-size: $mediumFontSize22;
  line-height: $lineHeightMultiplier * $mediumFontSize22;
}

.u-main-font--large {
  font-family: $font_stack;
  font-size: $largeFontSize;
  line-height: $lineHeightReducer * $largeFontSize;
}

.u-main-font--vlarge {
  font-family: $font_stack;
  font-size: $xLargeFontSize;
  line-height: $lineHeightMultiplier * $xLargeFontSize;
}

.u-main-font--vvlarge {
  font-family: $font_stack;
  font-size: $xxLargeFontSize;
  line-height: $xxLargeFontSize;
}

.u-marker-font--large {
  font-family: $font_marker_cursive;
  font-size: $largeFontSize;
  line-height: $lineHeightReducer * $largeFontSize;
}

.u-marker-font--vlarge {
  font-family: $font_marker_cursive;
  font-size: $xLargeFontSize;
  line-height: $lineHeightReducer * $xLargeFontSize;
}

.u-italic {
  font-style: italic;
}

.u-text-unselectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.u-font--14 {
  font-size: $smallFontSize14;
}

@include smallMobileOnly() {
  .u-main-font--small-mobile{
    font-size: $xSmallFontSize;
  }
}

@include desktopOnly() {
  .u-main-font--14--small--desktop {
    font-family: $font_stack;
    font-size: $smallFontSize14;
    line-height: $lineHeightMultiplier * $smallFontSize14;
  }

  .u-main-font--banner--desktop {
    font-family: $font_stack;
    font-size: $bannerFontSize;
    line-height: $lineHeightMultiplier * $largeFontSize;
  }
}

@include mobileOnly() {
  //all mobile sizes should be the step smaller than normal
  .u-font--input {
    font-size: $xSmallFontSize !important;
  }
  .u-text-oneline--mobile {
    white-space: nowrap;
  }
  //main font
  .u-main-font--vsmall {
    font-size: $xSmallFontSize;
    line-height: $lineHeightMultiplier * $xSmallFontSize;
  }

  .u-main-font--small {
    font-size: 14px;
    line-height: $lineHeightMultiplier * $xSmallFontSize;
  }

  .u-main-font--14--small {
    font-size: 14px;
    line-height: $lineHeightMultiplier * $xSmallFontSize;
  }

  .u-main-font--small--mobile {
    font-family: $font_stack;
    font-size: $smallFontSize;
    line-height: $lineHeightMultiplier * $smallFontSize;
  }

  .u-main-font--medium {
    font-size: 18px;
    line-height: $lineHeightMultiplier * $smallFontSize;
  }

  .u-main-font--large {
    font-size: 24px;
    line-height: $lineHeightMultiplier * $mediumFontSize;
  }

  .u-main-font--vlarge--mobile {
    font-size: 40px;
    line-height: $lineHeightMultiplier * 40px;
  }

  .u-main-font--vlarge {
    font-size: $largeFontSize;
    line-height: $lineHeightMultiplier * $largeFontSize;
  }

  .u-main-font--vvlarge {
    font-size: $xLargeFontSize;
    line-height: $lineHeightMultiplier * $xLargeFontSize;
  }

  .u-main-font--vvlarge--mobile {
    font-size: 70px;
    line-height: $lineHeightMultiplier * 70px;
  }

  .u-lineheight--19--mobile {
    line-height: "19px";
  }
}

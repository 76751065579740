$vsmallBorderRadius: 4px;
$smallBorderRadius: 8px;
$mediumBorderRadius: 16px;
$largeBorderRadius: 34px;
$landingPageBorderRadius: 100px;

.u-border--darkgrey {
  outline: 1px solid $borderDarkGrey;
}
.u-border--charcoal--500 {
  border: 1px solid $borderDarkGrey500;
}
.u-border--darkgrey--thick {
  border: 2px solid $borderDarkGrey;
}

.u-border--white {
  border: 2px solid $white;
}
.u-border--white--thick {
  border: 4px solid $white;
}
.u-border--magenta {
  border: 1px solid $magenta;
}
.u-border--lightmagenta {
  border: 8px solid #af81dd ;
}
.u-border--transparent--white {
  border: 8px solid rgba(255,255,255,0.48);
}
.u-outline--grey {
  outline: solid 1px $grey;
}
.u-outline--darkgrey {
  outline: solid 1px $borderDarkGrey;
}
.u-outline--grey {
  outline: solid 1px $grey;
}
.u-outline--pink {
  outline: 3px solid $pink;
  outline-offset: -3px;
}
.u-outline--pink--left--3 {
  border-left: 3px solid $pink;
}
.u-outline--pink--right--3 {
  border-right: 3px solid $pink;
}
.u-outline--pink--bottom--3 {
  border-bottom: 3px solid $pink;
}
.u-outline--pink--top--3 {
  border-top: 3px solid $pink;
  @extend .u-outline--pink--right--3;
  @extend .u-outline--pink--left--3;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.u-border--coles {
  border: 1px solid $coles;
}
.u-border--woolworths {
  border: 1px solid $woolworths;
}

.u-border--closeMatch {
  border: 1px solid $closeMatchBlue;
}

.u-border--coles--2 {
  border: 2px solid $coles;
}
.u-border--woolworths--2 {
  border: 2px solid $woolworths;
}

.u-border--left--white {
  border-left: 1px solid $white;
}

.u-border--left--magenta {
  border-left: 4px solid $magenta;
}
.u-border--left--transparent--white {
  border-left: 1px solid rgba(255,255,255,0.48);
}

.u-border--top--grey {
  border-top: 1px solid $borderGrey;
}
.u-border--top--transparent--white {
  border-top: 4px solid rgba(255,255,255,0.48);
}
.u-border--bottom--grey {
  border-bottom: 1px solid $borderGrey;
}
.u-border--bottom--darkgrey {
  border-bottom: 1px solid $borderDarkGrey;
}
.u-border--right--grey {
  border-right: 1px solid $borderGrey;
}
.u-border--right--white {
  border-right: 1px solid $white;
}
.u-border--right--transparent--white {
  border-right: 1px solid rgba(255,255,255,0.48);
}

.u-border--bottom--magenta--heavy {
  box-shadow: inset 0 -5px 0 $magenta;
}
.u-border--left--magenta--heavy {
  box-shadow: inset 8px 0 0 $magenta;
}

// Radii

.u-br--all {
  border-radius: 10000px; //hack
}

.u-br--tr {
  border-radius: 8px 0 80% 0;
}

.u-br--vsmall {
  border-radius: $vsmallBorderRadius;
}
.u-br--small--inset {
  border-radius: 6px;
} 
.u-br--small {
  border-radius: $smallBorderRadius;
  -webkit-border-radius: $smallBorderRadius;
  -moz-border-radius: $smallBorderRadius;
}
.u-br--medium {
  border-radius: $mediumBorderRadius;
  -webkit-border-radius: $mediumBorderRadius;
  -moz-border-radius: $mediumBorderRadius;
}
.u-br--large {
  border-radius: $largeBorderRadius;
  -webkit-border-radius: $largeBorderRadius;
  -moz-border-radius: $largeBorderRadius;
}

.u-br--bottom--vsmall {
  border-radius: 0 0 $vsmallBorderRadius $vsmallBorderRadius;
}
.u-br--bottom--small {
  border-radius: 0 0 $smallBorderRadius $smallBorderRadius;
}
.u-br--left--small {
  border-radius: $smallBorderRadius 0 0 $smallBorderRadius;
}
.u-br--right--small {
  border-radius: 0 $smallBorderRadius $smallBorderRadius 0;
}
.u-br--left--medium {
  border-radius: $mediumBorderRadius 0 0 $mediumBorderRadius;
}

.u-br--top--small {
  border-radius: $smallBorderRadius $smallBorderRadius 0 0;
  -webkit-border-radius: $smallBorderRadius $smallBorderRadius 0 0;
  -moz-border-radius: $smallBorderRadius $smallBorderRadius 0 0;
}
.u-br--top--medium {
  border-radius: $mediumBorderRadius $mediumBorderRadius 0 0;
}
.u-br--topleft--small {
  border-radius: $smallBorderRadius 0 0 0;
}

.u-br--bottomleft--small {
  border-radius: 0 0 0 $smallBorderRadius;
}

.u-br--bottomleft--xlarge {
  border-radius: 0 0 0 $landingPageBorderRadius;
}

//  strokes

.u-stroke--magenta {
  stroke: $magenta;
}

.u-stroke--grey {
  stroke: $darkGrey;
}

.u-br--bottomleft--large {
  border-radius: 0 0 0 $largeBorderRadius;
}

@include mobileOnly() {
  .u-border--bottom--grey--mobile {
    border-bottom: 1px solid $borderGrey;
  }
}

@include desktopOnly() {
  .u-br--bottom--small--desktop {
    border-radius: 0 0 $smallBorderRadius $smallBorderRadius;
  }
}

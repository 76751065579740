.o-modal {
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;

  // Z-index so that it is always above the other elements on the page
  z-index: 55;

  &__background {
    background-color: #00000086;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    position: absolute;
    display: flex;
    justify-items: center;
  }

  &--content {
    position: fixed;
  }

  &--content-bottom {
    position: fixed;
    bottom: 0;
  }

  &--content-centered {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%); /* for IE 9 */
    -webkit-transform: translate(-50%, -50%);
  }

  &__container {
    z-index: 2;
    position: relative;
  }
}

/* Mobile functionality */
@include mobileOnly() {
  .o-modal__container {
    // make sure the modal sits in the right position
    top: 0;
    left: 0;
  }

  .u-modal--content-bottom--mobile {
    bottom: 0;
    top: initial;
    left: 0;
    transform: none;
  }
}

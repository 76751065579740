// Fonts
$font_stack: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol";
$font: 400 100% /1.375 $font_stack;
$font_stack_console: "Monaco", "Menlo", "Ubuntu Mono", "Consolas",
  "source-code-pro", monospace;
$font_marker_cursive: 'Permanent Marker', cursive;

// Colours
$white: #ffffff;
$black: #000000;
$modalBlack: #00000086;
$lightGrey: #F0F0F0;
$grey: #fafafa;
$darkGrey: #abaaaa;
$borderGrey: #f2f2f2;
$borderDarkGrey: #eeeeee;
$borderDarkGrey500: #898385;
$violet: #9300DA;
$magenta: $violet;
$charcoal: #4c4c4c;
$charcoalText: #323232;
$confirmGreen: #82b931;
$backgroundGrey: #fafafa;
$yellow: #fff500;
$pink: #e868a2;
$closeMatchBlue: #3180B9;
$purple: #8000FF;
$vividCyan: #00E0FF;
$red: #e13153;
$brightRed: #ff0031;


// Brands
$woolworths: #82b931;
$coles: #ff0000;

:export {
  violetColor: $violet;
}

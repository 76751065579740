input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 30px;
    width: 30px;
    background: $violet;
    border-radius: 10px;
    margin-top: -14px;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    border: none;
    background: linear-gradient(0.25turn, $violet,  $violet);
  }
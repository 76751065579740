$hoverover-transition-time: 0.05s;
$hoverover-background-alpha: 0.02;

.u-hoverover--darker-background {
  -webkit-animation: fadeout-background $hoverover-transition-time ease-in; /* Safari, Chrome and Opera > 1.8.1 */
  -moz-animation: fadeout-background $hoverover-transition-time ease-in; /* Firefox < 16 */
  -ms-animation: fadeout-background $hoverover-transition-time ease-in; /* Internet Explorer */
  -o-animation: fadeout-background $hoverover-transition-time ease-in; /* Opera < 1.8.1 */
  animation: fadeout-background $hoverover-transition-time ease-in;
  &:hover {
    background-color: rgba(0, 0, 0, $hoverover-background-alpha);
    -webkit-animation: fadein-background $hoverover-transition-time ease-in; /* Safari, Chrome and Opera > 1.8.1 */
    -moz-animation: fadein-background $hoverover-transition-time ease-in; /* Firefox < 16 */
    -ms-animation: fadein-background $hoverover-transition-time ease-in; /* Internet Explorer */
    -o-animation: fadein-background $hoverover-transition-time ease-in; /* Opera < 1.8.1 */
    animation: fadein-background $hoverover-transition-time ease-in;
  }
}

@keyframes fadein-background {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, $hoverover-background-alpha);
  }
}
@keyframes fadeout-background {
  from {
    background-color: rgba(0, 0, 0, $hoverover-background-alpha);
  }
  to {
    background-color: rgba(0, 0, 0, 0);
  }
}

.u-hoverover--dropdown-menu {
  &:hover .u-hoverover--dropdown-menu--content {
    display: inline-block;
  }
}
.u-hoverover--dropdown-menu--content {
  display: none;
}

.u-glint--animation {
  background: linear-gradient(
      90deg,
      #0000 33%,
      rgba(255, 255, 255, 0.144) 50%,
      #0000 66%
    )
    #f3f3f3;
  background-size: 300% 100%;
  animation: shine 2s infinite;
}

@keyframes shine {
  0% {
    background-position: right;
  }
  /*100% {
  background-position: left; it's the default value, no need to define it
}*/
}

.u-fadein {
  animation: fadein 0.2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.u-fadeout {
  animation: fadeout 0.2s;
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.u-slide-in--left {
  animation: slideInLeft 0.5s ease-in-out;
}

@keyframes slideInLeft {
  from {
    right: -600px;
  }
  to {
    right: 0px;
  }
}

.u-slide-in--bottom {
  animation: slideInBottom 0.5s ease-in-out;
  position: absolute;
  bottom: 0;
}

@keyframes slideInBottom {
  from {
    bottom: -400px;
  }
  to {
    bottom: 0px;
  }
}

.o-animate-tick {
  animation: draw 1.5s ease-in-out forwards;
}

@keyframes draw {
  from {
    opacity: 0;
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
  }
  to {
    stroke-dashoffset: 0;
    opacity: 1;
  }
}

// FOR LANDING / MARKETING PAGE
.u-fadein--heading {
  -webkit-animation: fadein-heading 4s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein-heading 4s; /* Firefox < 16 */
  -ms-animation: fadein-heading 4s; /* Internet Explorer */
  -o-animation: fadein-heading 4s; /* Opera < 12.1 */
  animation: fadein-heading 4s;
}

@keyframes fadein-heading {
  from {
    opacity: 0;
  }
  to {
    margin-top: 0;
    opacity: 1;
  }
}

.u-growin {
  -webkit-animation: growin 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: growin 1s; /* Firefox < 16 */
  -ms-animation: growin 1s; /* Internet Explorer */
  -o-animation: growin 1s; /* Opera < 12.1 */
  animation: growin 1s;
}

@keyframes growin {
  from {
  transform: scale(0.6);
  } to {
  transform: scale(1.0);
      }
}

.u-slide {
  -webkit-animation: slide 20s linear infinite; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: slide 20s linear infinite; /* Firefox < 16 */
  -ms-animation: slide 20s linear infinite; /* Internet Explorer */
  -o-animation: slide 20s linear infinite; /* Opera < 12.1 */
  animation: slide 20s linear infinite;
}
.u-slide-carousel {
  opacity: 0;
}

.u-slide-carousel.active {
  opacity: 1;
}

@keyframes slide {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, -650px, 0); /* The image width */
  }
}

.u-slide-oposite {
  -webkit-animation: slideOposite 20s linear infinite; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: slideOposite 20s linear infinite; /* Firefox < 16 */
  -ms-animation: slideOposite 20s linear infinite; /* Internet Explorer */
  -o-animation: slideOposite 20s linear infinite; /* Opera < 12.1 */
  animation: slideOposite 20s linear infinite;
}

@keyframes slideOposite {
  0% {
    transform: translate3d(0, -650px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0); /* The image width */
  }
}

.u-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ffffff;
  margin: 0 5px;
}

.u-dot:nth-child(1) {
  animation-delay: 0s;
}

.u-dot:nth-child(2) {
  animation-delay: 0.5s;
}

.u-dot:nth-child(3) {
  animation-delay: 1s;
}
@keyframes dotAnimation {

  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}